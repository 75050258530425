<template>
  <v-card max-width="426" class="mx-auto px-3 px-md-6 py-6">
    <h1 class="font-medium-20 gray13--text text-center mb-2">
      {{
        $t("auth.registerPage.registerVerification.verificationType", {
          otpType,
        })
      }}
    </h1>

    <p class="gray7--text text-center mb-1">
      {{ $t("auth.registerPage.registerVerification.enterTheCodeSentTo") }}
    </p>

    <div class="text-center mb-6">
      <span class="font-medium-14 gray12--text me-2">{{ username }}</span>
      <router-link to="/auth/register" class="font-semiBold-14 primary--text">
        {{ $t("auth.registerPage.registerVerification.edit") }}
      </router-link>
    </div>

    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        v-model="otpModel.otp"
        :rules="otpRules"
        required
        :placeholder="
          $t('auth.registerPage.registerVerification.verificationCode', {
            otpType,
          })
        "
        filled
        hide-details="auto"
        type="tel"
        maxlength="5"
        class="theme-custom-input theme-custom-otp-input mb-8"
        @keypress="filterOnlyNumber"
      >
        <template v-slot:append>
          <v-btn
            v-if="isCounterExpired"
            @click="resendOtpCode"
            :loading="resendLoading"
            width="98"
            height="26"
            color="gray2"
            depressed
            class="resend-btn-border-radius"
          >
            <span class="font-medium-12">
              {{ $t("auth.registerPage.registerVerification.resendCode") }}
            </span>
          </v-btn>

          <span v-else class="font-medium-12 pt-1 mx-1">
            {{ countdownTimer }}
          </span>
        </template>
      </v-text-field>

      <v-btn
        :disabled="!valid"
        color="primary"
        block
        depressed
        height="56"
        :loading="loading"
        class="mb-4"
        type="submit"
      >
        <span class="font-semiBold-14">
          {{ $t("auth.registerPage.registerVerification.confirm") }}
        </span>
      </v-btn>

      <div class="text-center">
        <router-link to="/auth/login" class="font-semiBold-14 primary--text">
          {{ $t("auth.registerPage.registerVerification.cancelRegistration") }}
        </router-link>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import { AuthService } from "@/services";
import $countdown from "@/mixins/countdown";

export default {
  name: "register-verification",
  components: {},
  mixins: [$countdown],
  props: [],
  data() {
    return {
      loading: false,
      resendLoading: false,
      valid: false,
      otpType: "",
      username: "",
      otpModel: {
        otp: "",
        otpToken: "",
      },
      otpRules: [
        (v) =>
          !!v ||
          this.$t(
            "auth.registerPage.registerVerification.verificationCodeNotEmpty"
          ),
        (v) =>
          v.length >= 5 ||
          this.$t("auth.loginPage.passwordRecoveryOtp.verificationCodeLength"),
      ],
      countdownTimer: "",
      isCounterExpired: false,
      clockInterval: "",
    };
  },
  computed: {
    // otpTypeCamelCase() {
    //   let otp = this.otpType.toLowerCase();
    //   return otp.charAt(0).toUpperCase() + otp.slice(1);
    // },
  },
  async created() {
    await this.getOtpInfo();
    this.checkOtpType();
  },
  methods: {
    checkOtpType() {
      let otp = this.otpType.toLowerCase();
      this.otpType = otp.charAt(0).toUpperCase() + otp.slice(1);
    },
    otpToken() {
      return localStorage.getItem("otp_token");
    },
    async getOtpInfo() {
      await AuthService.otpInfo(this.otpToken()).then((res) => {
        localStorage.setItem("otp_token", res.data.data.otpToken);
        this.otpModel.otpToken = res.data.data.otpToken;
        this.username = res.data.data.username;
        this.otpType = res.data.data.otpType;
        this.$countdown(res.data.data.ttl);
      });
    },
    resendOtpCode() {
      this.resendLoading = true;
      AuthService.resendOtp(this.otpToken())
        .then((res) => {
          localStorage.setItem("otp_token", res.data.data.otpToken);
          this.otpModel.otpToken = res.data.data.otpToken;
          this.$countdown(res.data.data.ttl);
          this.isCounterExpired = !this.isCounterExpired;
          this.$toast.success(res.data.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
        })
        .finally(() => {
          this.resendLoading = false;
        });
    },
    submit() {
      this.loading = true;
      AuthService.completeRegister(this.otpModel)
        .then(() => {
          localStorage.removeItem("otp_token");
          this.$router.replace("/auth/register/succeed-registration");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterOnlyNumber(evt) {
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "RegisterVerification";
</style>
